import React, { useState, useEffect, useCallback, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const MultipleCertificatePrintButton = ({ multiPrintRow, setmultiPrintRow, setselectedSRfMultiple, clearSearch , setSearched, muliplePDfOption}) => {
  const navigate = useNavigate();
  const [certificate, setCertificate] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [SRFNoList, setSRFNoList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [uniqueServiceReqNumbers, setUniqueServiceReqNumbers] = useState([]);
  const [selectedServiceReqNumber, setSelectedServiceReqNumber] = useState(null);
  const [certificatesForServiceReq, setCertificatesForServiceReq] = useState([]);
  const [jobNumberRange, setJobNumberRange] = useState({ from: "", to: "" });
  const userType = localStorage.getItem("type");
  const clientId = localStorage.getItem("clientId");

  const fetchCertificates = useCallback(async () => {
    if (!selectedServiceReqNumber) return;
    try {
      const { data } = await axiosWithToken.post(BASE_URL + `dynamic`, {
        query: `SELECT id,jobNumber,serviceReqNumber,approvedby FROM certificatesView WHERE serviceReqNumber = '${selectedServiceReqNumber?.serviceReqNumber}'`,
      });
      setCertificate(data);
    } catch (err) {
      console.error("Data fetching error: ", err);
    }
  }, [selectedServiceReqNumber]);

  const fetchClientList = useCallback(async () => {
    try {
       let url = BASE_URL + `clients?_where=(status,eq,1)`;
      // If user type is 3
      if (userType == "3") {
        url += `~and(id,eq,${clientId})`;
      }
      const { data } = await axiosWithToken.get(url);
      setClientList(data);
    } catch (err) {
      console.error("Data fetching error: ", err);
    }
  }, []);

  const fetchSRFNoList = useCallback(async () => {
    try {
      const { data } = await axiosWithToken.get(BASE_URL +`srfs?_where=(status,eq,1)`);
      setSRFNoList(data);
    } catch (err) {
      console.error("Data fetching error: ", err);
    }
  }, []);

  useEffect(() => {
    fetchClientList();
    fetchSRFNoList();
  }, [fetchClientList, fetchSRFNoList]);

  useEffect(() => {
    fetchCertificates();
  }, [fetchCertificates]);

  useEffect(() => {
    if (selectedCompany) {
      const filtered = SRFNoList.filter(
        (item) => item.clientId === selectedCompany.id
      );
      setUniqueServiceReqNumbers(filtered);
      setSelectedServiceReqNumber(null);
      setJobNumberRange({ from: "", to: "" });
    } else {
      setUniqueServiceReqNumbers([]);
    }
  }, [selectedCompany, SRFNoList]);

  useEffect(() => {
    if (selectedServiceReqNumber) {
      setSearched(true);
      setCertificatesForServiceReq(certificate);
      setJobNumberRange({ from: "", to: "" });
    } else {
      setCertificatesForServiceReq([]);
    }
  }, [selectedServiceReqNumber, certificate]);

  const jobNumbers = useMemo(() => certificatesForServiceReq.map((cert) => cert.jobNumber), [certificatesForServiceReq]);

  const sortedJobNumbersAsc = useMemo(() => 
    [...jobNumbers].sort((a, b) => parseInt(a.split("/")[1]) - parseInt(b.split("/")[1])), 
    [jobNumbers]
  );
  
  const sortedJobNumbersDesc = useMemo(() => 
    [...jobNumbers].sort((a, b) => parseInt(b.split("/")[1]) - parseInt(a.split("/")[1])), 
    [jobNumbers]
  );

  const isRangeValid = useCallback((from, to) => {
    if (!from || !to) return false;
    const fromNumber = parseInt(from.split("/")[1]);
    const toNumber = parseInt(to.split("/")[1]);
    return (
      fromNumber <= toNumber &&
      certificatesForServiceReq.some(
        (cert) =>
          parseInt(cert.jobNumber.split("/")[1]) >= fromNumber &&
          parseInt(cert.jobNumber.split("/")[1]) <= toNumber
      )
    );
  }, [certificatesForServiceReq]);

  const getFilteredCertificatesByJobNumberRange = useCallback((certificates, from, to) => {
    if (!from || !to) return [];
    const fromNumber = parseInt(from.split("/")[1]);
    const toNumber = parseInt(to.split("/")[1]);
    return certificates.filter(
      (item) =>
        parseInt(item.jobNumber.split("/")[1]) >= fromNumber &&
        parseInt(item.jobNumber.split("/")[1]) <= toNumber &&
        muliplePDfOption ? item.approvedby : !item.approvedby
    );
  }, []);

  const handlePrint = () => {
    const filteredCertificates = getFilteredCertificatesByJobNumberRange(
      certificatesForServiceReq,
      jobNumberRange.from,
      jobNumberRange.to
    );
    const selectedIds = filteredCertificates.map((cert) => cert.id);
    if (selectedIds.length > 101) {
      toast.warn("You can't select more than 100 Certificates.");
      return;
    }
    if (selectedIds.length === 0) {
      toast.warn("In this SRF Number There is no certificate approved");
      return;
    }
    navigate(`/certificate/viewCertificate/${selectedIds.join(",")}`);
    // console.log("Selected IDs for printing:", selectedIds);
  };

  return (
    <div style={{ padding: "1rem 0" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Autocomplete
            sx={{ m: 0, minWidth: "100%" }}
            size="small"
            options={clientList}
            getOptionLabel={(option) => option.companyName}
            value={selectedCompany}
            onChange={(event, newValue) => setSelectedCompany(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select Company" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {selectedCompany && (
          <Grid item xs={12} md={3}>
            <Autocomplete
              sx={{ m: 0, minWidth: "100%" }}
              size="small"
              options={uniqueServiceReqNumbers}
              getOptionLabel={(option) => option.serviceReqNumber}
              value={selectedServiceReqNumber}
              onChange={(event, newValue) =>{
                setSelectedServiceReqNumber(newValue);
                setselectedSRfMultiple(newValue?.serviceReqNumber);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select SRF" variant="outlined" fullWidth />
              )}
            />
          </Grid>
        )}

        {selectedServiceReqNumber && (
          <>
            <Grid item xs={12} md={2}>
              <Autocomplete
                sx={{ m: 0, minWidth: "100%" }}
                size="small"
                options={sortedJobNumbersAsc}
                value={jobNumberRange.from}
                onChange={(event, newValue) =>
                  setJobNumberRange({ ...jobNumberRange, from: newValue })
                }
                renderInput={(params) => (
                  <TextField {...params} label="From Job Number" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Autocomplete
                sx={{ m: 0, minWidth: "100%" }}
                size="small"
                options={sortedJobNumbersDesc}
                value={jobNumberRange.to}
                onChange={(event, newValue) =>
                  setJobNumberRange({ ...jobNumberRange, to: newValue })
                }
                renderInput={(params) => (
                  <TextField {...params} label="To Job Number" variant="outlined" fullWidth />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={1.5}>
          {isRangeValid(jobNumberRange.from, jobNumberRange.to) && (
            <Button variant="contained" color="primary" size="small" onClick={handlePrint}>
              Download
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={1}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              setmultiPrintRow(!multiPrintRow);
              clearSearch();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MultipleCertificatePrintButton;
